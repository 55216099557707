type TExperimentsProps = {
  labsConfig: TLabsConfig;
};

export const labsABSwitchInState = (labsConfig: TLabsConfig, abSwitch: string, group: string) =>
  labsConfig?.abSwitches?.[abSwitch]?.group === group;

const experiments = ({ labsConfig }: TExperimentsProps) => {
  const getLabsConfig = ({ experiment, group }: { experiment: string; group: string }) =>
    labsABSwitchInState(labsConfig, experiment, group) || false;
  const getFeatureSwitch = ({ feature }: { feature: string }) =>
    labsConfig?.featureSwitches?.includes(feature) || false;

  return {
    skinTest: getFeatureSwitch({ feature: 'skinTest' }),
    enableTmp:
      getLabsConfig({ experiment: 'AUR5875', group: 'B' }) || getLabsConfig({ experiment: 'AUR5875', group: 'C' }),
    isECGJsBannerCDN: getLabsConfig({ experiment: 'ECGJsBannerCDN', group: 'B' }),
    isShippingCampaignEnabled: labsABSwitchInState(labsConfig, 'BNL21106', 'B'),
    isDAC7CommunicationEnabled: labsABSwitchInState(labsConfig, 'BNL26495', 'B'),
    isFetchResultsOnceEnabled: labsABSwitchInState(labsConfig, 'BNL23670', 'B'),
    isFetchResultsOnceAllCatEnabled: labsABSwitchInState(labsConfig, 'BNL23670v2', 'B'),
    isSmartBannerExperimentEnabled: labsABSwitchInState(labsConfig, 'BNL25733', 'B'),
    isCarsExtendedAdDetailsEnabled: labsABSwitchInState(labsConfig, 'BNL27987', 'B'),
    isCarsModelRedirectEnabled: labsABSwitchInState(labsConfig, 'BNL31662', 'B'),
    isDefaultPriceTypeForCarsEnabled: labsABSwitchInState(labsConfig, 'BNL28372v3', 'B'),
    isPubmaticJSEnabled: labsABSwitchInState(labsConfig, 'BNL30517', 'B'),
    isIntowowEnabledNLGroupA: labsABSwitchInState(labsConfig, 'BNL28785', 'A'),
    isIntowowEnabledNLGroupB: labsABSwitchInState(labsConfig, 'BNL28785', 'B'),
    isIntowowEnabledTWHGroupA: labsABSwitchInState(labsConfig, 'BNL29367', 'A'),
    isIntowowEnabledTWHGroupB: labsABSwitchInState(labsConfig, 'BNL29367', 'B'),
    isLazyLoading300Enabled: labsABSwitchInState(labsConfig, 'BNL33290', 'B'),
    isLazyLoading600Enabled: labsABSwitchInState(labsConfig, 'BNL33290', 'C'),
    isLazyLoading900Enabled: labsABSwitchInState(labsConfig, 'BNL33290', 'D'),
    isSortingEnabled: labsABSwitchInState(labsConfig, 'BNL30290', 'B'),
  };
};
export const experimentsToTrackDatadogRUM = ['BNL33290'];

export default experiments;
