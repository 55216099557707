type TParams = {
  isPubmaticJSEnabled: boolean;
  isIntowowEnabledNLGroupA: boolean;
  isIntowowEnabledNLGroupB: boolean;
  isIntowowEnabledTWHGroupA: boolean;
  isIntowowEnabledTWHGroupB: boolean;
};

const isIntowowEnabled = ({
  isPubmaticJSEnabled,
  isIntowowEnabledNLGroupA,
  isIntowowEnabledNLGroupB,
  isIntowowEnabledTWHGroupA,
  isIntowowEnabledTWHGroupB,
}: TParams): boolean => {
  if (!isPubmaticJSEnabled) {
    return false;
  }
  const isIntowowEnabledNL = isIntowowEnabledNLGroupA || isIntowowEnabledNLGroupB;
  const isIntowowEnabledTWH = isIntowowEnabledTWHGroupA || isIntowowEnabledTWHGroupB;

  return isIntowowEnabledNL || isIntowowEnabledTWH;
};
export default isIntowowEnabled;
