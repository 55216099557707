import React from 'react';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';

import { nextEnvironment, nextSeller } from '@/HOC/utils/nextProps';
import { EnvironmentProvider } from '@/client/contexts/EnvironmentContext';

import '@/client/styles/app.scss';

import GptScript from '@/HOC/components/GptScript';
import PrebidScript from '@/HOC/components/PrebidScript';
import AdsenseScript from '@/HOC/components/AdsenseScript';
import AwsWAFScript from '@/HOC/components/AwsWAFScript';
import SourcePointScript from '@/HOC/components/SourcePointScript';
import IntowowScript from '@/HOC/components/IntowowScript';

interface LrpAppProps extends AppProps {
  seller: TSeller;
  environment: IEnvironmentContext;
  bannersConfig: TBannerServerData;
}

const LrpApp = ({ Component, pageProps, seller, environment, bannersConfig }: LrpAppProps) => {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <SourcePointScript
        sourcePointProperties={environment?.urls?.sourcePoint}
        useSourcePointCMP={environment?.useSourcePointCMP}
      />
      <PrebidScript prebidUrl={environment?.urls?.auroraPrebidUrl} labsConfig={environment?.labsConfig} />
      <AdsenseScript />
      <AwsWAFScript srcUrl={environment?.urls?.awsWAFScriptUrl} />
      <EnvironmentProvider value={environment}>
        <Component {...pageProps} seller={seller} />
      </EnvironmentProvider>
      <IntowowScript labsConfig={environment?.labsConfig} tenantContext={environment?.tenantContext} />
      <GptScript
        gptUrl={environment?.urls?.gptUrl}
        labsConfig={environment?.labsConfig}
        pwtPubId={environment?.properties?.pwtPubId}
        pwtProfIdJS={environment?.properties?.pwtProfIdJS}
        bannersConfig={bannersConfig}
      />
    </>
  );
};

LrpApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { req, res } = appContext.ctx;

  // when the user presses the back button, this getInitialProps gets called.
  // No req/res is available, so we need to reconstruct the returned props from another source
  if (process.browser && !req && !res) {
    const environment = nextEnvironment();
    const seller = nextSeller();

    return {
      ...appProps,
      seller,
      environment,
    };
  }

  const { seller, environmentContext, bannersConfig } = (res as any)?.locals;

  return {
    ...appProps,
    seller,
    environment: environmentContext,
    bannersConfig,
  };
};

export default LrpApp;
